<!--
 * @Description: 添加员工
 * @Author: xiawenlong
 * @Date: 2021-04-27 11:41:42
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-24 10:11:40
-->
<template>
  <div class="class-add-course">
    <el-button type="primary" @click="$router.push('/class/list')">返回班级列表</el-button>
    <table-list
      title="添加班级课程"
      :loading="loading"
      :data="classList"
      :btns="btns(this)"
      :columns="columns(this)"
      :options="{ selection: true, operatesWidth: 200, selectable: selectEvent, rowDrop: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @row-drop-end="handleRowDropEnd"
    >
      <div slot="subTitle" class="subTitle">已添加课程</div>
    </table-list>
  </div>
</template>

<script>
const btns = _this => [
  {
    label: '从课程库选择',
    method: _this.handleAddByLibrary,
  },
  {
    label: '从课程商城选择',
    method: _this.handleAddByShop,
  },
  {
    label: '从培养方案选择',
    method: _this.handleAddByPlan,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'courseName',
    label: '课程名称',
    minWidth: 250,
  },
  {
    prop: 'courseType',
    label: '课程类型',
    minWidth: '80',
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
  },
  {
    prop: 'durationCount',
    label: '总时长（分钟）',
    minWidth: '120',
    formatter: row => {
      return row.durationCount
    },
  },
  {
    prop: 'buyDetail',
    label: '课程购买情况',
    minWidth: '120',
    formatter: row => {
      return { 0: '自建', 1: '未购买', 2: '已购买', 9: '已下架' }[row.buyDetail]
    },
  },
  {
    prop: 'validTime',
    label: '有效期',
    minWidth: '120',
  },
  {
    prop: 'credit',
    label: '学分设置',
    minWidth: '100',
    sortable: true,
    render: (h, { row }) => {
      return row.editable
        ? h('div', [
            h('el-input-number', {
              style: {
                marginRight: '10px',
                width: '60px',
              },
              props: {
                value: row.creditTemp,
                controls: false,
                min: 1,
                max: 99,
                size: 'small',
              },
              on: {
                input: function(e) {
                  row.creditTemp = e
                },
              },
            }),
            h(
              'el-button',
              {
                props: {
                  type: 'text',
                  size: 'small',
                },
                on: {
                  click: () => {
                    _this.updateCredit(row)
                  },
                },
              },
              '保存',
            ),
            h(
              'el-button',
              {
                props: {
                  type: 'text',
                  size: 'small',
                },
                on: {
                  click: () => {
                    row.editable = false
                  },
                },
              },
              '取消',
            ),
          ])
        : h('div', [
            h(
              'span',
              {
                style: {
                  margin: '4.5px',
                  width: '30px',
                  display: 'inline-block',
                  textAlign: 'center',
                },
              },
              row.credit === null ? '-' : row.credit,
            ),
            !row.isSettle
              ? h(
                  'el-button',
                  {
                    props: {
                      type: 'text',
                      size: 'small',
                    },
                    on: {
                      click: function() {
                        row.editable = true
                      },
                    },
                  },
                  row.credit === null ? '设置' : '修改',
                )
              : null,
          ])
    },
  },
  {
    prop: 'isExamRequire',
    label: '是否需要考试',
    minWidth: '120',
    render: (h, { row }) => {
      return [
        h('el-switch', {
          props: {
            value: row.isExamRequire,
            disabled: row.isSettle,
          },
          on: {
            change: () => {
              _this.updateExamRequire(row)
            },
          },
        }),
      ]
    },
  },
  {
    prop: 'examList',
    label: '设置考试',
    minWidth: '200',
    render: (h, { row }) => {
      return row.examList.length
        ? h('div', [
            h('span', row.examList[0].examTitle),
            !row.isSettle
              ? h('i', {
                  class: 'el-icon-circle-close',
                  style: {
                    cursor: 'pointer',
                    color: '#ff7b33',
                    marginLeft: '10px',
                  },
                  on: {
                    click: () => {
                      _this.deleteExam(row)
                    },
                  },
                })
              : null,
          ])
        : !row.isSettle && row.isExamRequire
        ? h(
            'div',
            {
              style: {
                cursor: 'pointer',
                color: '#ff7b33',
              },
              on: {
                click: () => {
                  _this.$router.push(`/class/addCourse/exam/${row.classCourseId}`)
                },
              },
            },
            [
              h('i', {
                class: 'el-icon-circle-plus',
              }),
              h('span', '设置'),
            ],
          )
        : ''
    },
  },
]
import TableList from '@/components/TableList'
import {
  classCourses,
  classRemove,
  classCourseCreditUpdate,
  classCourseExamRequireUpdate,
  classCourseExamUpdate,
  classCourseSort,
} from '@/api/class'
import to from 'await-to'
export default {
  name: 'ClassAddStudent',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      columns,
      loading: false,
      classList: [],
      selectClassCourse: [],
      deptList: [],
      pager: {
        total: 0,
        size: 500,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getClasses()
  },
  methods: {
    async getClasses() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classId } = this.$route.params
      this.loading = true
      const [res, err] = await to(classCourses({ classId, current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      res.data.list.map(item => {
        this.$set(item, 'editable', false)
        this.$set(item, 'creditTemp', item.credit)
      })
      this.classList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getClasses()
    },
    handleAddByLibrary() {
      const { classId } = this.$route.params
      this.$router.push(`/class/addCourse/${classId}/library`)
    },
    handleAddByShop() {
      const { classId } = this.$route.params
      this.$router.push(`/class/addCourse/${classId}/shop`)
    },
    handleAddByPlan() {
      const { classId } = this.$route.params
      this.$router.push(`/class/addCourse/${classId}/plan`)
    },
    // 删除
    async handleDelete() {
      if (!this.selectClassCourse.length) return this.$message.warning('请先选择要删除的课程')
      let classCourseIds = []
      this.selectClassCourse.map(item => classCourseIds.push(item.classCourseId))
      this.$confirm('确认删除课程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { classId } = this.$route.params
        const [, err] = await to(classRemove({ classId, classCourseIds }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getClasses()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectClassCourse = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getClasses()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getClasses()
    },
    selectEvent(row) {
      return !row.isSettle
    },

    async updateCredit(row) {
      row.editable = false
      const { classCourseId, creditTemp } = row
      const [, err] = await to(classCourseCreditUpdate({ classCourseId, credit: creditTemp }))
      if (err) return this.$message.warning(err.msg)
      row.credit = creditTemp
    },
    async updateExamRequire(row) {
      const { classCourseId, isExamRequire } = row
      const [, err] = await to(
        classCourseExamRequireUpdate({ classCourseId, isExamRequire: !isExamRequire }),
      )
      if (err) return this.$message.warning(err.msg)
      row.isExamRequire = !row.isExamRequire
      if (!row.isExamRequire) {
        row.examList = []
      }
    },
    async deleteExam(row) {
      const { classCourseId } = row
      const [, err] = await to(classCourseExamUpdate({ classCourseId, examList: [] }))
      if (err) return this.$message.warning(err.msg)
      row.examList = []
    },
    async handleRowDropEnd({ newIndex, oldIndex }) {
      const currentId = this.classList[oldIndex].classCourseId
      this.classList.splice(newIndex, 0, this.classList.splice(oldIndex, 1)[0])
      var newArray1 = this.classList.slice(0)
      this.classList = []
      this.$nextTick(async function() {
        this.classList = newArray1
        console.log(this.classList)
        const nextId = this.classList[newIndex + 1]
          ? this.classList[newIndex + 1].classCourseId
          : ''
        const [, err] = await to(classCourseSort({ currentId, nextId }))
        if (err) return this.$message.warning(err.msg)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.class-add-course {
  .subTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #4d4e5d;
    margin-bottom: 12px;
  }
  ::v-deep .table-list {
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      display: none;
    }
  }
}
</style>
